<template>
  <div class="flex justify-start items-start pt-3 pb-9 rounded-2xl">
    <div class="pr-6 w-20 flex-shrink-0 text-center">
      <div class="text-sub text-xs mt-1">
        {{ item.shooting_date | weekday }}
      </div>
      <div class="text-main">
        <sup class="text-lg">{{ item.shooting_date | month }}/</sup>
        <span class="font-extrabold text-3xl">{{ item.shooting_date | date }}</span>
      </div>
      <ui-label
        v-if="item.shooting_date === today"
        color="orange"
        class="text-xxs font-bold mt-2 mx-auto p-1 w-12"
      >
        今日
      </ui-label>
    </div>
    <section class="flex-grow">
      <h3 class="text-lg text-main mb-1">
        {{ item.title }}
      </h3>
      <div class="mb-4">
        <button
          v-for="actress in item.actresses"
          :key="actress.id"
          class="focus:outline-none"
          @click="$emit('click-actress', actress.id)"
        >
          <img
            v-lazy="`/uploads/actresses/${actress.thumbnail}`"
            class="inline-block w-13 h-13 rounded-xl shadow-xl object-cover mr-2"
          />
        </button>
      </div>
      <div>
        <h4 class="text-xs text-sub mb-1">
          撮影品番
        </h4>
        <ui-label
          v-for="label in item.labels"
          :key="label.id"
          class="text-xxs inline-block px-2 py-1 mr-1 mb-1"
        >
          {{ label.name }}
        </ui-label>
      </div>
      <div v-if="item.evernote_url">
        <a target="_blank" :href="item.evernote_url" class="block mt-3 text-sm text-purple-400">
          好きなもの調査を見る
          <font-awesome-icon class="text-opacity-75 ml-1" :icon="['fas', 'external-link-alt']" />
        </a>
      </div>
      <div>
        <router-link :to="`/timetables/${item.id}`" class="block mt-3 text-sm text-purple-400">
          香盤表を確認
          <font-awesome-icon class="text-opacity-75 ml-1" :icon="['fas', 'clock']" />
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { parse, format } from 'date-fns'
import ja from 'date-fns/locale/ja'

export default {
  name: 'ScheduleItem',
  filters: {
    title(actresses) {
      return actresses.map(actress => actress.name).join('＆')
    },
    weekday(date) {
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'iiii', { locale: ja }).toUpperCase()
    },
    date(date) {
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'd')
    },
    month(date) {
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'M')
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    today() {
      return format(new Date(), 'yyyy-MM-dd')
    },
  },
}
</script>
