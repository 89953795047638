<template>
  <div>
    <ui-select
      v-model="selectedLabelId"
      label="撮影品番で絞り込み"
      class="mb-4"
      :options="labels"
    />
    <div class="text-right">
      <button
        v-if="mode === 'upcoming'"
        class="text-indigo-500 text-sm mr-2 focus:outline-none active:text-indigo-400"
        @click="mode = 'past'"
      >
        <font-awesome-icon :icon="['fas', 'history']" />
        過去の撮影を確認
      </button>
      <button
        v-else
        class="text-indigo-500 text-sm mr-2 focus:outline-none active:text-indigo-400"
        @click="mode = 'upcoming'"
      >
        <font-awesome-icon :icon="['fas', 'history']" />
        今後の撮影を確認
      </button>
    </div>
    <ui-transition>
      <div v-if="!isLoading">
        <div v-if="filteredSchedules.length">
          <schedule-item
            v-for="schedule in filteredSchedules"
            :key="schedule.id"
            :item="schedule"
            @click-actress="showActressModal($event)"
            @click-compose-tweet="
              tweetScheduleId = $event
              tweetCopyDialog = true
            "
          />
        </div>
        <div v-else>
          <div class="text-center text-sub text-opacity-75 py-6 bg-level2 text-sm">
            <span v-if="mode === 'upcoming'">
              今後の撮影予定は登録されていません
            </span>
            <span v-else>
              撮影済みの予定はありません
            </span>
          </div>
        </div>
      </div>
    </ui-transition>
    <actress-modal v-model="actressModalIsOpen" :actress-id="selectedActressId" />
    <tweet-template-dialog v-model="tweetCopyDialog" :schedule-id="tweetScheduleId" />
  </div>
</template>

<script>
import { parse, isToday, isYesterday } from 'date-fns'
import ScheduleService from '../services/ScheduleService'
import LabelService from '../services/LabelService'
import ScheduleItem from '../components/schedule/ScheduleItem.vue'
import ActressModal from '../components/actress/ActressModal.vue'
import TweetTemplateDialog from '../components/schedule/TweetTemplateDialog.vue'

export default {
  name: 'Schedule',
  components: {
    ScheduleItem,
    ActressModal,
    TweetTemplateDialog,
  },
  data: () => ({
    labels: [],
    schedules: [],
    isLoading: true,
    tweetCopyDialog: false,
    tweetScheduleId: null,
    actressModalIsOpen: false,
    selectedActressId: null,
  }),
  computed: {
    filteredSchedules() {
      const selectedLabelId = Number(this.selectedLabelId)
      if (!selectedLabelId) {
        return this.schedules
      }
      return this.schedules.filter(schedule =>
        schedule.labels.find(label => label.id === selectedLabelId),
      )
    },
    mode: {
      get() {
        return this.$store.getters['app/scheduleMode']
      },
      set(value) {
        this.$store.commit('app/scheduleMode', value)
      },
    },
    selectedLabelId: {
      get() {
        return this.$store.getters['app/scheduleSelectedLabelId']
      },
      set(value) {
        this.$store.commit('app/scheduleSelectedLabelId', value)
      },
    },
  },
  watch: {
    mode() {
      this.fetch()
    },
  },
  async created() {
    LabelService.get().then(labels => {
      this.labels = [{ id: 0, name: 'ALL' }, ...labels].map(item => ({
        value: item.id,
        text: item.name,
      }))
    })
    await this.fetch()
    this.$store.dispatch('layout/restorePosition')
  },
  methods: {
    async fetch() {
      this.isLoading = true
      this.schedules = await ScheduleService.get(this.mode)
      this.isLoading = false
    },
    showActressModal(actressId) {
      this.selectedActressId = actressId
      this.actressModalIsOpen = true
    },
    async onCopy() {
      this.tweetCopyDialog = false
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: 'コピーしました！',
      })
    },
    async generateTweet() {
      const base = await ScheduleService.getTweetBase({ ...this.tweetTarget })
      const shootingDate = parse(base.shooting_date, 'yyyy-MM-dd', new Date())

      let reldate
      if (isToday(shootingDate)) {
        reldate = '今日'
      } else if (isYesterday(shootingDate)) {
        reldate = '昨日'
      } else {
        reldate = '先日'
      }
      const actressNames = base.actresses.map(actress => {
        if (actress.twitter_id) {
          return `#${actress.name} ちゃん (@${actress.twitter_id} )`
        }
        return `#${actress.name} ちゃん`
      })

      this.url = `${base.url}${base.shooting_date.replace(/-/g, '')}`
      const top = `${reldate}は${actressNames.join('、')}と撮影しました🎥💕

発売をお楽しみに❣️

`

      const footer = !this.url
        ? ''
        : `会員限定動画や新作のお知らせを
イチ早く受け取りませんか？
↓
${this.url}
      `.trim()
      this.tweet = `${top}${footer}`
    },
  },
}
</script>
